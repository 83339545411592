<template>
  <div>
    <!-- pic95BF4189-FEB8-42CF-9F56-241B16FC0616.jpg -->
    <img class="rounded" style="height: 247px;" src="@/assets/news-network/legouniverse-com.jpg">
    <p>
      Welcome to the all new LEGOuniverse.com! Enjoy connecting with this constantly evolving info hub for LEGO Universe, the first massively multiplayer online game (MMOG) for LEGO fans!
    </p>
    <p>
      Unleash your creativity in the new LEGO Universe Creation Lab! It’s a hot spot for filling the game with your inspirations! Or find answers to the questions the LEGO Universe development team hears most in the new Game Information section!
    </p>
    <p>
      Tuning into the upgraded LEGO Universe News Network will take you behind the scenes with the game’s developers—and sign up for the News Network’s free
      <router-link :to="{ name: 'newsletter' }">email newsletter</router-link>
      for VIP exclusives!
    </p>
    <p>
      LEGO Universe’s info hub now also includes a section especially for parents!
    </p>
    <p>
      Have fun!
    </p>
  </div>
</template>
